<template>
  <v-col>
    <card
      class="pa-1 ma-5"
      style="min-height: 520px;"
      outlined
    >
      <v-card-title>
        <!-- <v-icon
          large
          left
          :color="statusObj[status].color"
        >
          fa fa-file-text-o
        </v-icon>
        <span
          class="text-h4 font-weight-black font-weight-light"
          :color="statusObj[status].color"
        >
          {{ statusObj[status].title }}
        </span> -->
      </v-card-title>
      <v-alert
        text
        close-icon="fa fa-close"
        icon="fa4 fa-bar-chart"
        dense
        class="mt-4 py-1"
        :color="(isDocking ==='NULL'?'red':'success') || (isPush ==='NULL' ?'red':'success')"
      >
        共有<strong> {{ projectTotal }} </strong>个信息
      </v-alert>
      <v-simple-table>
        <thead>
          <tr>
            <th>信息名称</th>
            <th class="text-right">
              操作
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(project, index) in projectList"
            :key="index"
          >
            <td>{{ orgQX && type === 'reportChild'?project.data.parentName: project.name }}</td>
            <td class="text-right">
              <v-btn
                class="px-2 ml-1"
                min-width="0"
                small
                color="success"
                @click="see(orgQX,type,project)"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  fa4 fa-eye
                </v-icon>
                查看
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-card-actions v-if="show">
        <v-btn
          block
          color="grey darken-1"
          outlined
          :to="allList()"
        >
          全部信息...
        </v-btn>
      </v-card-actions>
    </card>
  </v-col>
</template>

<script>
  export default {
    props: {
      isPush: {
        type: String,
        default: 'NULL',
      },
      formId: {
        type: String,
        // 默认全部
        default: '',
      },
      _from: {
        type: String,
        default: 'org',
      },
      title: {
        type: String,
        default: '项目',
      },
      isDocking: {
        type: String,
        default: '',
      },
      type: {
        type: String,
        default: '',
      },
      status: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        // 待审核
        show: true,
        projectList: [],
        projectTotal: 0,
        formIds: '',
        orgQX: false,
      }
    },
    created () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
    },
    mounted () {
      setTimeout(() => {
        this.getMyProject()
      }, 500);
    },
    methods: {
      see (orgQX, type, project) { // 查看项目
        if (orgQX && type === 'reportChild') {
          this.$router.push({
            name: 'ReportDetails',
            params: {
              formId: project.data.parentFormId,
              id: project.data.parentID,
            },
            query: {
              dynamicId: project.id,
            },
          })
        } else {
          this.$router.push({
            name: 'ReportDetails',
            params: {
              formId: project.formId,
              id: project.id,
            },
          })
        }
      },
      getformIds () {
        if (JSON.parse(localStorage.newForms).length) {
          const list = JSON.parse(localStorage.newForms)[0].children
          const formIds = []
          list.map(val => {
            formIds.push(val.id)
          })
          this.formIds = formIds.toString()
        }
      },
      allList () {
        if (this.orgQX && this.type === 'reportChild') {
          return `/city-list?isDocking=${this.isDocking}`
        } else {
          if (this.isPush === '是') {
            return `/examine-list2/?isPush=${this.isPush}&formId=${this.formId}&_from=${this._from}&title=${this.title}`
          } else if (this.isPush === 'NULL') {
            return `/examine-list2/?isPush=${this.isPush}&formId=${this.formId}&_from=${this._from}&title=${this.title}`
          } else {
            return `/examine-list2/?formId=${this.formId}&title=${this.title}&_from=${this._from}`
          }
        }
      },
      // 获取自己提报的项目
      getMyProject () {
        var searchParame = { formId: this.formId, page: 0, size: 5, _from: this._from }
        if (this.status) {
          searchParame = { ...searchParame, status: this.status }
        }
        if (this.orgQX && this.type === 'reportChild') {
          // 推送园区子表单
          searchParame = { ...searchParame, isDocking: this.isDocking }
          delete searchParame.isPush
          console.log('searchParame1', searchParame, this.orgQX, this.type, this.isDocking);
        } else if (this.isPush) {
          searchParame = { ...searchParame, isPush: this.isPush }
          delete searchParame.isDocking
          console.log('searchParame2', searchParame, this.orgQX, this.type, this.isDocking);
        } else {
          searchParame = { ...searchParame }
          delete searchParame.isDocking
          delete searchParame.isPush
          console.log('searchParame3', searchParame, this.orgQX, this.type, this.isDocking);
        }
        this.$axios.get('/reports/query', {
          params: searchParame,
        })
          .then(res => {
            console.log('res111111', res, searchParame);

            const resData = res.data.data
            this.projectList = resData.content
            this.projectTotal = resData.totalElements
            if (!res.data.data.totalElements) {
              this.show = false
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
